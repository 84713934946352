const Jobroles = [
  { value: "Developer Frontend", label: "Developer Frontend" },
  { value: "Designer Developer", label: "Designer Developer" },
  { value: "Manager End", label: "Manager End" },
  { value: "Software Engineer", label: "Software Engineer" },
  { value: "Software Architect", label: "Software Architect" },
  { value: "Business Analyst", label: "Business Analyst" },
  { value: "IT Consultant", label: "IT Consultant" },
  { value: "System Administrator", label: "System Administrator" },
  { value: "Database Administrator", label: "Database Administrator" },
  { value: "Network Administrator", label: "Network Administrator" },
  { value: "Security Analyst", label: "Security Analyst" },
  { value: "DevOps Engineer", label: "DevOps Engineer" },
  { value: "Cloud Architect", label: "Cloud Architect" },
  { value: "Full Stack Developer", label: "Full Stack Developer" },
  { value: "Frontend Developer", label: "Frontend Developer" },
  { value: "Backend Developer", label: "Backend Developer" },
  { value: "Mobile App Developer", label: "Mobile App Developer" },
  { value: "Web Designer", label: "Web Designer" },
  { value: "UX/UI Designer", label: "UX/UI Designer" },
  { value: "Product Manager", label: "Product Manager" },
  { value: "Project Manager", label: "Project Manager" },
  { value: "QA Analyst", label: "QA Analyst" },
  { value: "System Analyst", label: "System Analyst" },
  { value: "Business Consultant", label: "Business Consultant" },
  { value: "Data Scientist", label: "Data Scientist" },
  { value: "Data Engineer", label: "Data Engineer" },
  { value: "Frontend Architect", label: "Frontend Architect" },
  { value: "Backend Architect", label: "Backend Architect" },
  { value: "Mobile App Architect", label: "Mobile App Architect" },
  { value: "UX Design Lead", label: "UX Design Lead" },
  { value: "UI Design Lead", label: "UI Design Lead" },
  { value: "Agile Coach", label: "Agile Coach" },
  { value: "Scrum Master", label: "Scrum Master" },
  { value: "DevOps Architect", label: "DevOps Architect" },
  { value: "Cloud Engineer", label: "Cloud Engineer" },
  { value: "Business Development Manager", label: "Business Development Manager" },
  { value: "Sales Engineer", label: "Sales Engineer" },
  { value: "Customer Success Manager", label: "Customer Success Manager" },
  { value: "Technical Writer", label: "Technical Writer" },
  { value: "Data Analyst", label: "Data Analyst" },
  { value: "IT Support Engineer", label: "IT Support Engineer" },
  { value: "Network Engineer", label: "Network Engineer" },
  { value: "System Engineer", label: "System Engineer" },
  { value: "IT Security Specialist", label: "IT Security Specialist" },
  { value: "Software QA Engineer", label: "Software QA Engineer" },
  { value: "UI/UX Engineer", label: "UI/UX Engineer" },
  { value: "UI/UX Designer", label: "UI/UX Designer" },
  { value: "Cloud Consultant", label: "Cloud Consultant" },
  { value: "ERP Consultant", label: "ERP Consultant" },
  { value: "Java Developer", label: "Java Developer" },
  { value: "Python Developer", label: "Python Developer" },
  { value: "Ruby on Rails Developer", label: "Ruby on Rails Developer" },
  { value: "PHP Developer", label: "PHP Developer" },
  { value: "JavaScript Developer", label: "JavaScript Developer" },
  { value: "TypeScript Developer", label: "TypeScript Developer" },
  { value: "Angular Developer", label: "Angular Developer" },
  { value: "React Developer", label: "React Developer" },
  { value: "Vue.js Developer", label: "Vue.js Developer" },
  { value: "Node.js Developer", label: "Node.js Developer" },
  { value: "Express.js Developer", label: "Express.js Developer" },
  { value: "Django Developer", label: "Django Developer" },
  { value: "Sales Associate", label: "Sales Associate" },
  { value: "Customer Service Representative", label: "Customer Service Representative" },
  { value: "Administrative Assistant", label: "Administrative Assistant" },
  { value: "Office Manager", label: "Office Manager" },
  { value: "Executive Assistant", label: "Executive Assistant" },
  { value: "Human Resources Coordinator", label: "Human Resources Coordinator" },
  { value: "Marketing Coordinator", label: "Marketing Coordinator" },
  { value: "Public Relations Specialist", label: "Public Relations Specialist" },
  { value: "Event Planner", label: "Event Planner" },
  { value: "Graphic Designer", label: "Graphic Designer" },
  { value: "Content Marketer", label: "Content Marketer" },
  { value: "Social Media Coordinator", label: "Social Media Coordinator" },
  { value: "Customer Success Specialist", label: "Customer Success Specialist" },
  { value: "Account Manager", label: "Account Manager" },
  { value: "Financial Analyst", label: "Financial Analyst" },
  { value: "Business Analyst", label: "Business Analyst" },
  { value: "Accountant", label: "Accountant" },
  { value: "Bookkeeper", label: "Bookkeeper" },
  { value: "Legal Assistant", label: "Legal Assistant" },
  { value: "Paralegal", label: "Paralegal" },
  { value: "Research Assistant", label: "Research Assistant" },
  { value: "Data Entry Clerk", label: "Data Entry Clerk" },
  { value: "Executive Secretary", label: "Executive Secretary" },
  { value: "Office Assistant", label: "Office Assistant" },
  { value: "Receptionist", label: "Receptionist" },
  { value: "Customer Support Specialist", label: "Customer Support Specialist" },
  { value: "Quality Assurance Specialist", label: "Quality Assurance Specialist" },
  { value: "Compliance Officer", label: "Compliance Officer" },
  { value: "Training Coordinator", label: "Training Coordinator" },
  { value: "Procurement Specialist", label: "Procurement Specialist" },
  { value: "Logistics Coordinator", label: "Logistics Coordinator" },
  { value: "Inventory Manager", label: "Inventory Manager" },
  { value: "Security Guard", label: "Security Guard" },
  { value: "Maintenance Technician", label: "Maintenance Technician" },
  { value: "security-officer", label: "Security Officer" },
{ value: "personal-assistant", label: "Personal Assistant" },
  {value: "Other", label: "Other"}
];

export default Jobroles;